import React from "react"
import Link from "gatsby-link"

export default () => (

    <div>
        <h1>about</h1>
        <Link to="/">forside</Link>
        <br />
        <br />

        
    </div>
)